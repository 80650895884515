import * as React from 'react'
import BrandRepairServices from './BrandRepairServices'

const RepairServices = () => {
  return (
    <div className="my-24">
      <h2 className="underline-title my-5">Repair Services</h2>
      <BrandRepairServices/>
    </div>
  )
}

export default RepairServices
