import * as React from 'react'
import { useState, useEffect, useRef, useCallback } from 'react'
import useMediaQuery from '../../hooks/useMediaQuery'
import { DefaultSliderButtonNext, DefaultSliderButtonPrev } from './slider/SliderButton'
import PropTypes from 'prop-types'

function scrollParentToChild(parent, child) {
  let parentRect = parent.getBoundingClientRect()
  let childRect = child.getBoundingClientRect()
  const scrollLeft = childRect.left - parentRect.left
  const scrollRight = childRect.right - parentRect.right
  if (Math.abs(scrollLeft) < Math.abs(scrollRight)) {
    parent.scrollLeft += scrollLeft
  } else {
    parent.scrollLeft += scrollRight
  }
}

const DefaultItemsPerScreen = step => {
  const isMd = useMediaQuery('(min-width: 768px)')
  const isLg = useMediaQuery('(min-width: 1024px)')
  const theStep = (step || 0) <= 0 ? Number.MAX_SAFE_INTEGER : step
  return [
    useCallback(() => {
      if (isLg) return Math.min(4, theStep)
      return isMd ? Math.min(2, theStep) : 1
    }, [isLg, isMd, theStep]),
    'w-full md:w-1/2 lg:w-1/4',
  ]
}

export const Slider = ({
  children,
  childrenClassName = 'flex-none snap-start',
  className = '',
  buttonPrev = DefaultSliderButtonPrev,
  buttonNext = DefaultSliderButtonNext,
  itemsPerScreenFactory = DefaultItemsPerScreen,
  step = 0,
}) => {
  const scrollPanel = useRef()
  const [itemsPerScreen, childrenClasses] = itemsPerScreenFactory(step)
  const [currentItem, setCurrentItem] = useState(0)
  const [forward, setForward] = useState(true)
  const [clicked, setClicked] = useState(false)
  const numberOfChildren = Array.isArray(children) ? children.length : 1
  const assertInRange = (val, min, max) => {
    if (val > max) return max
    return val < min ? min : val
  }

  const isFirst = () => currentItem <= 0
  const isLast = () => currentItem + itemsPerScreen() >= numberOfChildren

  const wrapChildren = (child, index) => {
    return (
      <div
        key={index}
        className={`${childrenClassName} ${childrenClasses || 'flex-none snap-start'}`}
      >
        {child}
      </div>
    )
  }
  const scrollToNext = () => {
    setCurrentItem(item => {
      return Math.max(
        Math.min(
          Array.isArray(children) ? children.length - itemsPerScreen() : 0,
          item + itemsPerScreen()
        ),
        0
      )
    })
    setForward(true)
    setClicked(true)
  }
  const scrollToPrev = () => {
    setCurrentItem(item => {
      return Math.max(
        Math.min(
          Array.isArray(children) ? children.length - itemsPerScreen() : 0,
          item - itemsPerScreen()
        ),
        0
      )
    })
    setForward(false)
    setClicked(true)
  }
  useEffect(() => {
    if (!clicked) return
    let scrollPanelChildren = scrollPanel.current.children
    let shift = forward ? itemsPerScreen() - 1 : 0
    let showElement = assertInRange(currentItem + shift, 0, scrollPanelChildren.length - 1)
    let trueCurrentItem = assertInRange(showElement - shift, 0, scrollPanelChildren.length - 1)
    if (showElement !== trueCurrentItem) {
      setCurrentItem(trueCurrentItem)
    }
    scrollParentToChild(scrollPanel.current, scrollPanelChildren[showElement])
  }, [currentItem, children, forward, clicked, itemsPerScreen])

  // NOTE: This is a hack to get the slider to work on the first render
  useEffect(() => {
    if (!scrollPanel?.current) return
    if (clicked) return
    const scrollPanelChildren = scrollPanel.current.children
    for (let i = 0; i < scrollPanelChildren.length; i++) {
      if (
        scrollPanelChildren[i].offsetLeft + scrollPanelChildren[i].offsetWidth / 2 >=
        scrollPanel.current.scrollLeft
      ) {
        setCurrentItem(i)
        break
      }
    }
  }, [scrollPanel?.current?.scrollLeft])

  return (
    <div className={' relative ' + className}>
      <div
        className="flex snap-x snap-mandatory flex-row flex-nowrap overflow-x-auto scroll-smooth md:overflow-hidden"
        ref={scrollPanel}
        id={'scrollPanel'}
      >
        {Array.isArray(children) ? children.map(wrapChildren) : wrapChildren(children, 1)}
      </div>
      {buttonPrev({ onClick: scrollToPrev, disabled: isFirst() })}
      {buttonNext({ onClick: scrollToNext, disabled: isLast() })}
    </div>
  )
}

Slider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  childrenClassName: PropTypes.string,
  className: PropTypes.string,
  buttonPrev: PropTypes.func,
  buttonNext: PropTypes.func,
  itemsPerScreenFactory: PropTypes.func,
  step: PropTypes.number,
}

export default Slider
