import * as React from 'react'
import CustomerLetterSlider from '../sliders/CustomerLetterSlider'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { processGatsbyImageDataMock } from '../../common/gatsbyImageData'

const CustomerRepairLetters = ({ brand }) => {
  const {
    letters: { nodes: repairsPDFs },
  } = useStaticQuery(graphql`
    {
      letters: allStrapiClientLetter {
        nodes {
          brand
          urlPath
          title
          content
          image {
            gatsbyImageDataMock
          }
        }
      }
    }
  `)
  repairsPDFs.forEach(letter => {
    processGatsbyImageDataMock(letter.image)
  })

  // const letters = brand ? repairsPDFs.filter(letter => letter.brand === brand) : repairsPDFs
  // sort repairs, if brand is a match put it first
  const letters = repairsPDFs.sort((a, b) => {
    if (a.brand === brand) return -1
    if (b.brand === brand) return 1
    return 0
  })
  if (letters.length === 0) return null
  return (
    <div className="w-full max-w-[1366px] mx-auto px-12 my-20">
      <h2 className="underline-title my-5">PAST REPAIRS</h2>
      <CustomerLetterSlider>
        {letters.map(letter => {
          return (
            <Link to={letter.urlPath} key={letter.urlPath} className="group">
              <h3 className="font-black uppercase line-clamp-2 h-12 mb-2">{letter.title}</h3>
              <div className="w-full flex justify-center items-center">
                <GatsbyImage
                  alt={letter.title}
                  image={getImage(letter.image)}
                  className={'aspect-video object-cover'}
                />
              </div>
              <div className="whitespace-pre-wrap ">
                <p
                  className="line-clamp-12 text-base font-normal font-serif lg:line-clamp-[15] my-4 max-h-[19.5rem] leading-6 "
                  dangerouslySetInnerHTML={{ __html: letter.content }}
                />
              </div>
              <div className="block text-red-700 cursor-pointer group-hover:font-bold my-3">
                Read More
              </div>
            </Link>
          )
        })}
      </CustomerLetterSlider>
    </div>
  )
}

CustomerRepairLetters.propTypes = {
  brand: PropTypes.string,
}

export default CustomerRepairLetters
