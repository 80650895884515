import * as React from 'react'
import { IconChevronLeft, IconChevronRight } from '../../Icons'
import PropTypes from 'prop-types'

const SliderButton = ({ children, onClick, disabled = false, className = 'left-0' }) => {
  return (
    <button
      className={
        className +
        ' absolute top-1/2 z-10 h-full w-8 -translate-x-10 -translate-y-1/2 bg-gray-600 bg-opacity-10 text-gray-400 hover:bg-gray-600 hover:bg-opacity-40 hover:text-white' +
        (disabled ? ' hidden' : ' block')
      }
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const DefaultSliderButtonPrev = ({ onClick, disabled }) => {
  return (
    <SliderButton onClick={onClick} disabled={disabled} className="left-0">
      <IconChevronLeft className={'translate-x-2'}/>
    </SliderButton>
  )
}
export const DefaultSliderButtonNext = ({ onClick, disabled }) => {
  return (
    <SliderButton onClick={onClick} disabled={disabled} className="right-0">
      <IconChevronRight className={'-translate-x-2'}/>
    </SliderButton>
  )
}

SliderButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}
DefaultSliderButtonNext.propTypes = DefaultSliderButtonPrev.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default SliderButton