import * as React from 'react'
import { useCallback } from 'react'
import TailwindScreens from '../../helpers/tailwind'
import useMediaQuery from '../../hooks/useMediaQuery'
import { IconChevronLeft, IconChevronRight } from '../Icons'
import Slider from '../sliders/Slider'
import PropTypes from 'prop-types'

const Custom3ItemsPerXlScreen = () => {
  const isMd = useMediaQuery('(min-width: 768px)')
  const isXl = useMediaQuery(TailwindScreens.xl)
  return [useCallback(() => {
    if (isXl) return 3
    return isMd ? 2 : 1
  }, [isXl, isMd]), 'w-full md:w-1/2 xl:w-1/3']
}

const ButtonPrev = ({ onClick, isHidden }) => {
  return (
    <button
      className={
        'absolute top-1/2 left-0 z-10 py-5 group px-2 -translate-x-10 -translate-y-1/2 bg-red-700  text-white hover:bg-red-800 ' +
        (isHidden ? ' hidden' : ' block')
      }
      onClick={onClick}
    >
      <IconChevronLeft className={'-translate-x-0 group-hover:-translate-x-1 duration-300'}/>
    </button>
  )
}
ButtonPrev.propTypes = {
  onClick: PropTypes.func,
  isHidden: PropTypes.bool,
}

const ButtonNext = ({ onClick, isHidden }) => {
  return (
    <button
      className={
        'absolute top-1/2 right-0 z-10 py-5 group px-2 -translate-y-1/2 translate-x-10 bg-red-700  text-white hover:bg-red-800 ' +
        (isHidden ? ' hidden' : ' block')
      }
      onClick={onClick}
    >
      <IconChevronRight className={'-translate-x-0 group-hover:translate-x-1 duration-300'}/>
    </button>
  )
}
ButtonNext.propTypes = {
  onClick: PropTypes.func,
  isHidden: PropTypes.bool,
}

const CustomerLetterSlider = ({ children }) => {
  return (
    <Slider
      childrenClassName="flex-none snap-start px-4"
      itemsPerScreenFactory={Custom3ItemsPerXlScreen}
      buttonNext={ButtonNext}
      buttonPrev={ButtonPrev}
    >
      {children}
    </Slider>
  )
}

CustomerLetterSlider.propTypes = {
  children: PropTypes.node,
}

export default CustomerLetterSlider
